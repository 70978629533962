import { makeStyles } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { Markdown } from '@/components/common/Markdown'
import { PageContainer } from '@/components/common/PageContainer'
import { PageCover } from '@/components/common/PageCover'
import { PageTitle } from '@/components/common/PageTitle'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "data-protection" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    dataProtection: strapiDataProtection {
      content
    }
  }
`

const useStyles = makeStyles(() => ({
  text: {
    '& ol, ul': {
      padding: 0,
      listStylePosition: 'inside',
    },
    '& ul p': {
      maxWidth: '90%',
      display: 'inline-block',
      verticalAlign: 'top',
      margin: 0,
    },
  },
}))

const DataProtectionPage: FC = () => {
  const classes = useStyles()
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)
  return (
    <>
      <SEO title="Data Protection" />
      <PageCover image={image} title="Data Protection" />
      <PageContainer background="default">
        <PageTitle>Data Protection</PageTitle>
        <Markdown className={classes.text}>
          {data.dataProtection.content}
        </Markdown>
      </PageContainer>
    </>
  )
}

export default DataProtectionPage
